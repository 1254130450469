<template>
    <div class="women-container">
        <!-- empty -->
        <div class="empty" :style="{ 'height': `${emptyHeight}px` }"></div>
        <!-- content text -->
        <Row class="text" type="flex" justify="center" align="middle">
            <Col :span="22" :md="18" :lg="18">
                <p>
                    A sophisticate woman is a person who is extremely cultured and classy. The fashion of this person aims to maintain an elegant, well dressed image with modren outfit, without in any way going overboard. She will take minute care of presenting herself with utmost perfection. 
                </p>
            </Col>
        </Row>
        <!-- <div class="text">
            <p>
                A sophisticate woman is a person who is extremely cultured and classy. The fashion of this person aims to maintain an elegant, well dressed image with modren outfit, without in any way going overboard. She will take minute care of presenting herself with utmost perfection. 
            </p>
        </div> -->
        <!-- content -->
        <Row class="women" type="flex" justify="center" align="middle">
            <Col :span="22" :md="18" :lg="18">
                <ul class="waterfall"> 
                    <li v-for="item in 6" :key="item" class="item">
                        <img :src="require(`@img/women/w-${item}.jpg`)">
                    </li> 
                </ul>
            </Col>
        </Row>
        <div class="footer">
            <img :src="require(`@img/women/footer.jpg`)" alt="">
            <copy-right />
        </div>
    </div>
</template>

<script>
import common from '@M/common';
import CopyRight from '@C/CopyRight';

export default {
    name: 'Women',
    mixins: [common],
    components: {
        CopyRight
    },
    data() {
        return {}
    },
    created() {
        this.setCoverImage(require('@img/women/banner.jpg'));
    },
    mounted() {
        this.addCoverResize();
    },
    beforeDestroy() {
        this.removeCoverResize();
    }
}
</script>

<style lang="scss">
.women-container .text {
    word-break: break-all;
    background: #2F2F2D;
    color: #fff;
    height: 350px;
    // padding: 5rem 3rem;

    p {
        line-height: 2;
        word-break: break-word;
    }
}
.women {
    background: #fff;
    padding: 1rem 0 6rem 0;
    .waterfall { 
        column-count: 2;
        column-gap: 0px;
        .item {
            img {
                width: 100%;
                height: inherit;
                vertical-align: middle;
            }
        }
    }

}
.footer {
    width: 100%;
    img {
        width: 100%;
        vertical-align: bottom;
    }
}
</style>